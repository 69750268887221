/* src/styles/animations.css */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes pulse {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 0.8; }
}

@keyframes networkFloat {
  0% { background-position: 0 0; }
  100% { background-position: 22px 22px; }
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes drift {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-10px, -10px); }
  100% { transform: translate(0, 0); }
}

@keyframes glow {
  0%, 100% { filter: brightness(1); }
  50% { filter: brightness(1.3); }
}

/* Background Elements */
.neural-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0.1;
  animation: networkFloat 30s linear infinite;
}

.gradient-sphere {
  position: absolute;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, rgba(58,123,213,0.2) 0%, rgba(0,210,255,0.1) 50%, rgba(0,0,0,0) 70%);
  border-radius: 50%;
  filter: blur(40px);
  animation: float 6s ease-in-out infinite;
}

/* Section Transitions */
.section-transition {
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1),
              opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.section-enter {
  opacity: 0;
  transform: translateY(30px);
}

.section-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.section-exit {
  opacity: 1;
  transform: translateY(0);
}

.section-exit-active {
  opacity: 0;
  transform: translateY(-30px);
}